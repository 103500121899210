import React from 'react'
import './style.less';
import Link from "../Link";
import Img from "gatsby-image";
import language from "../../../data/language.yaml";

class MemberListingItem extends React.Component {
    render() {

        const member = this.props.member;

        if(parseInt(member.membership_level) < 3) {
            return (
                <div className="member-listing-item" data-color={member.type.acf.listing_colour}>
                    {member.featured_image && (
                        <Link to={member.path}><Img fluid={member.featured_image.localFile.childImageSharp.fluid}/></Link>
                    )}
                    <span className="content-wrap">
                        <span className="text-wrap">
                            <Link to={member.path}><span className="title" dangerouslySetInnerHTML={{__html: member.name}} /></Link>
                            <span className="desc">
                                {member.snippet}
                            </span>
                        </span>
                        <Link className="more"
                              to={member.path}>
                            {language.member_list.read_more[this.props.locale]}
                        </Link>
                        {/*<Link className="add-bubble" to="/">*/}
                            {/*{language.member_list.add_to_trip[this.props.locale]}*/}
                            {/*<span className="icon"></span>*/}
                        {/*</Link>*/}
                    </span>
                </div>
            );
        } else {
            return (
                <div className="member-listing-item" data-color={member.type.acf.listing_colour}>
                    {member.featured_image && (
                        <Link to={member.website_url}><Img fluid={member.featured_image.localFile.childImageSharp.fluid}/></Link>
                    )}
                    <span className="content-wrap">
                        <span className="text-wrap">
                            <Link to={member.website_url}><span className="title" dangerouslySetInnerHTML={{__html: member.name}} /></Link>
                            <span className="desc">
                                {member.snippet}
                            </span>
                        </span>
                        <Link className="more"
                              to={member.website_url}>
                            {language.member_list.visit_website[this.props.locale]}
                        </Link>
                    </span>
                </div>
            );
        }
    }
}

export default MemberListingItem
